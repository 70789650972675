<template>
  <va-sidebar
    v-if="pronto"
    :width="width"
    :minimized="minimized"
    :minimizedWidth="minimizedWidth"
    color="#fff"
  >
    <menu-minimized v-if="minimized && !isMobile()" :items="items" />
    <menu-accordion v-else :items="items" @clicou="this.$emit('clicou')"/>
  </va-sidebar>
</template>

<script>
import { useGlobalConfig } from 'vuestic-ui';
import MenuAccordion from './menu/MenuAccordion.vue';
import MenuMinimized from './menu/MenuMinimized.vue';
import NavigationRoutesPainel from './NavigationRoutesPainel';


export default {
  name: "painel-sidebar",
  components: {
    MenuAccordion,
    MenuMinimized,
  },
  props: {
    width: { type: String, default: '16rem' },
    color: { type: String, default: "secondary" },
    minimized: { type: Boolean, required: true },
    minimizedWidth: {
      type: Boolean,
      required: false,
      default: undefined
    },
  },
  created() {
    this.getItems()
  },
  methods:{
    isMobile() {
      return screen.width <= 760;
    },
    async getItems(){
      for (let itemKey in NavigationRoutesPainel.routes){
        const routeResolved = await this.$router.resolve({
          name: NavigationRoutesPainel.routes[itemKey].name,
        });
        if(this.$Auth.checkPerm(routeResolved.meta.perm)){
          this.items.push(NavigationRoutesPainel.routes[itemKey])
        }
      }
      this.pronto = true
    }
  },
  data() {
    return {
      pronto: false,
      items: []
    };
  },
  computed: {
    computedClass() {
      return {
        "app-sidebar--minimized": this.minimized
      };
    },
    colors() {
      return useGlobalConfig().getGlobalConfig().colors
    },
  },
};
</script>

<style lang="scss">
.va-sidebar {
  flex-shrink: 0;

  .va-collapse__body {
    margin-top: 0 !important;
  }
  &__menu {
    padding: 2rem 0;
    transition: var(--va-sidebar-transition);
  }

  &-item {
    &-content {
      padding: 0.75rem 1rem;
    }

    &__icon {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .app-layout__content .app-layout__sidebar-wrapper:not(.minimized) {
    top: 4rem;
    bottom: unset !important;
  }
}
</style>
