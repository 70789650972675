import {FetchWs} from "@/functions/FetchWs";
import {setDadosForm, setOptionsForm} from "@/components/monta-form/functions";

function padStr(i: number) {
    return (i < 10) ? "0" + i : "" + i;
}

export async function refresh(vue: any): Promise<void>
{
    let query = `?a=a`;
    if(vue.Crud?.listTable?.filters){
        for (const [key, value] of Object.entries(vue.Crud.listTable.filters)) {
            let val = value;
            if (value instanceof Date) {
                val = padStr(value.getFullYear()) + '-' + padStr(value.getMonth() + 1) + '-' + padStr(value.getDate())
            }
            query = query + `&${key}=${val}`
        }
    }
    const response = await FetchWs(vue.Crud.listTable.endpoint + query);
    if(response && response.success && Array.isArray(response.data)){
        vue.Crud.listTable.items = assertList(vue, response.data);
    } else {
        vue.Crud.listTable.items = [];
    }
}

function assertList(vue:any, objs: any[]): any[]
{
    objs.forEach((value:any, key:number) => {
        objs[key].acoes_id = value[vue.Crud.listTable.idKey]
    })
    return objs;
}

export async function remove(id: number, vue:any): Promise<void>
{
    const endpoint = vue.Crud.remove.endpoint.replace(':id', id);
    await FetchWs(endpoint, 'POST');
    await refresh(vue)
}

export async function submitNewForm( vue: any, data: any)
{
    const body = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)){
            value.forEach((item) => {
                if(item instanceof Blob){
                    body.append("documento", item)
                }
            });
        } else if (typeof value === 'string') {
            body.append(key, value);
        }
    }
    const response = await FetchWs(vue.Crud.newForm.data.endpoint, 'POST',body);
    if(response?.success) {
        vue.modalNew = false;
        await refresh(vue)
    }
}

export async function openModalNew(vue: any){
    if(vue.Crud.newForm.customOptions){
        for (const [key, func] of Object.entries(vue.Crud.newForm.customOptions)) {
            if (typeof func === 'function') {
                setOptionsForm(vue.Crud.newForm, key, await func())
            }
        }
    }
    vue.modalNew = true
}

export async function openModalEdit(vue: any, id: string){
    vue.Crud.editForm.data.acoes_id = id;
    console.log(vue.Crud.listTable.items)
    const obj = vue.Crud.listTable.items.find((item:any) => item.acoes_id === id)
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'string' || typeof value === 'number' ) {
            setDadosForm(vue.Crud.editForm, key, value)
        }
    }
    if(vue.Crud.editForm.customOptions){
        for (const [key, func] of Object.entries(vue.Crud.editForm.customOptions)) {
            if (typeof func === 'function') {
                setOptionsForm(vue.Crud.editForm, key, await func())
            }
        }

    }
    vue.modalEdit = true
}

export async function submitEditForm( vue: any, data: any)
{
    const body = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (Array.isArray(value)){
            value.forEach((item) => {
                if(item instanceof Blob){
                    body.append("documento", item)
                }
            });
        } else if (typeof value === 'string') {
            body.append(key, value);
        }
    }
    const endpoint = vue.Crud.editForm.data.endpoint.replace(':id', vue.Crud.editForm.data.acoes_id);
    const response = await FetchWs(endpoint, 'POST',body);
    if(response?.success) {
        vue.modalEdit = false;
        vue.modalActions = false;
        await refresh(vue)
    }
}