import { createVuestic } from 'vuestic-ui';
import { createApp } from 'vue'
// import { createGtm } from 'vue-gtm'
import App from './App.vue'
import router from './router'
import vuesticGlobalConfig from './global-config'
import moment from 'moment'
import {Auth} from "@/functions/Auth";
import MontaForm from "@/components/monta-form/MontaForm.vue";
import MontaListagem from "@/components/monta-listagem/MontaListagem.vue";
import MontaCrud from "@/components/monta-crud/MontaCrud.vue";

moment.locale('pt-br');

const app = createApp(App)
app.use(router)
// if (process.env.VUE_APP_GTM_ENABLED === 'true') {
//   const gtmConfig = {
//     id: process.env.VUE_APP_GTM_KEY,
//     debug: false,
//     vueRouter: router,
//   }
//   app.use(createGtm(gtmConfig))
// }
app.use(createVuestic(), vuesticGlobalConfig)
app.config.globalProperties.$Auth = new Auth()
app.component('MontaForm', MontaForm)
app.component('MontaListagem', MontaListagem)
app.component('MontaCrud', MontaCrud)
app.mount('#app')

