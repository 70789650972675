export default {
  colors: {
    primary: '#1852c6',
    secondary: '#ffc600',
    background: '#f6f7f6',
    success: '#3d9209',
    info: '#2c82e0',
    danger: '#e42222',
    warning: '#ffd43a',
    white: '#ffffff',
    dark: '#262824',
    gray: '#767c88',
  }
}
