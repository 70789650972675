
export function setOptionsForm(form: any, index: string, options: string[]){
    const tmpLine = form.struct[getTmpLineKey(form, index)];
    if(tmpLine) {
        form.struct[getTmpLineKey(form, index)][tmpLine.findIndex((obj: any) => obj.name == index)].options = options;
    }
}

export function setDadosForm(form: any, index: string, value: string|number){
    const tmpLine = form.struct[getTmpLineKey(form, index)];
    console.log(tmpLine)
    if(tmpLine){
        form.struct[getTmpLineKey(form, index)][tmpLine.findIndex((obj: any) => obj.name == index)].value = value;
    }
}

export function getTmpLineKey(form: any, index: string){
    console.log(form)
    console.log(index)
    return form.struct.findIndex((line: any) => line.findIndex((obj: any) => obj.name == index) !== -1);
}


export function setFormValues(vue: any, form: string, values: any){
    vue[form].struct.forEach((line: any, lineKey: any) => {
        line.forEach((field: any, fieldKey: any) => {
            const index: string = vue[form].struct[lineKey][fieldKey].name;
            vue.editedItem[index] = values[index] ? values[index] : null;
            setDadosForm(vue[form], index, values[index] ? values[index] : null)
        })
    })
}

export function delFormValues(vue: any, form: string){
    vue[form].struct.forEach((line: any, lineKey: any) => {
        line.forEach((field: any, fieldKey: any) => {
            const index = vue[form].struct[lineKey][fieldKey].name;
            delDadosForm(vue, form, index)
        })
    })
}

export function delDadosForm(vue: any, form: string, index: string){
    const tmpLine = vue[form].struct[getTmpLineKey(vue[form], index)];
    vue[form].struct[getTmpLineKey(vue[form], index)][tmpLine.findIndex((obj: any) => obj.name == index)].value = "";
}
