import {Auth} from "@/functions/Auth";
import {GenericError} from "@/functions/Error";
import {LoaderInit, LoaderStop} from "@/functions/Loader";
import {ProcessaResponse} from "@/functions/ProcessaResponse";

export async function FetchWs(uri: string, method = 'GET', body: any = {}, headers: any = {}, loader = true) {
    if (loader){
        LoaderInit();
    }

    const $Auth = new Auth()
    if (!headers.Authorization) {
        if ($Auth.JWT) {
            headers.Authorization = `Bearer ${$Auth.JWT}`;
        }
    }

    headers = {...headers}

    const request: RequestInit = {
        method: method,
        credentials: 'include',
        headers: headers
    };
    if (method !== 'GET') {
        request.body = body;
    }

    return await fetch(`${process.env.VUE_APP_URL_WS}/${uri}`, request)
        .then((r) => {
            if ([403].includes(r.status)) {
                $Auth.sair();
            }
            return r.json().then(json => ProcessaResponse(json));
        })
        .catch(GenericError)
        .finally(()=>{
            if(loader){
                LoaderStop()
            }
        })
}
