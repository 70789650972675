import PainelLayout from '@/layout/painel-layout.vue'
import EmBreve from '@/pages/painel/breve/EmBreve.vue'

//@todo melhorar nomeclatura
export default
{
    name: 'painel',
    path: '/',
    component: PainelLayout,
    redirect: 'painel-chamados-listagem',
    children: [
        {
            name: 'painel-chamados-listagem',
            path: 'chamados/listagem',
            component: () => import('@/pages/painel/chamados/listagem/Chamados.vue'),
            meta: {
                name: 'Listagem de Chamados',
            },
        },
        {
            name: 'painel-chamados-listagem-pontuacao',
            path: 'chamados/listagem-pontuacao',
            component: () => import('@/pages/painel/chamados/listagem-pontuacao/Chamados.vue'),
            meta: {
                name: 'Listagem de Pontuação',
            },
        },
        {
            name: 'painel-chamados-detalhes',
            path: 'chamados/detalhes/:cha_id',
            component: () => import('@/pages/painel/chamados/detalhes/Detalhes.vue'),
            meta: {
                name: 'Detalhes do Chamado',
            },
        },
        {
            name: 'em-breve',
            path: 'em-breve',
            component: EmBreve,
        },
        {
            name: 'graficos',
            path: 'graficos',
            component: () => import('@/pages/painel/graficos/Graficos.vue'),
        },
        {
            name: 'usuarios',
            path: 'usuarios',
            component: () => import('@/pages/painel/usuarios/listagem/Usuarios.vue'),
            meta: {
                name: 'Listagem de Usuários',
            },
        },
        {
            name: 'novo-usuario',
            path: 'usuarios/novo',
            component: () => import('@/pages/painel/usuarios/novo/NovoUsuario.vue'),
            meta: {
                name: 'Novo Usuário',
            },
        },
        {
            name: 'mudar-senha',
            path: 'usuarios/senha',
            component: () => import('@/pages/painel/usuarios/senha/MudarSenha.vue'),
            meta: {
                name: 'Alterar Senha',
            },
        },
        {
            name: 'editar-usuario',
            path: 'usuarios/editar/:id',
            component: () => import('@/pages/painel/usuarios/editar/EditarUsuario.vue'),
            meta: {
                name: 'Editar Usuário',
            },
        },
        {
            name: 'editar-permissoes',
            path: 'usuarios/permissoes/:id',
            component: () => import('@/pages/painel/usuarios/permissoes/EditarPermissoes.vue'),
            meta: {
                name: 'Editar Permissões',
            },
        },
        {
            name: 'clientes',
            path: 'clientes',
            component: () => import('@/pages/painel/clientes-novo/Crud.vue'),
            meta: {
                name: 'Clientes',
            },
        },
        {
            name: 'clientes-contatos',
            path: 'clientes/contatos/:id',
            component: () => import('@/pages/painel/clientes-novo/contatos/Crud.vue'),
            meta: {
                name: 'Contatos',
            },
        },
        {
            name: 'clientes-servicos',
            path: 'clientes/servicos/:id',
            component: () => import('@/pages/painel/clientes-novo/servicos/Crud.vue'),
            meta: {
                name: 'Serviços',
            },
        },
        {
            name: 'novo-cliente',
            path: 'clientes/novo',
            component: () => import('@/pages/painel/clientes/novo/NovoCliente.vue'),
        },
        {
            name: 'editar-cliente',
            path: 'clientes/editar/:id',
            component: () => import('@/pages/painel/clientes/editar/EditarCliente.vue'),
        },
        {
            name: 'perfil-trocar-senha',
            path: 'perfil/trocar-senha/:id',
            component: () => import('@/pages/painel/meu-perfil/TrocaSenha.vue'),
            meta: {
                name: 'Trocar Senha'
            },
        },
        {
            name: 'grupos',
            path: 'grupos',
            component: () => import('@/pages/painel/grupos/listagem/Grupos.vue'),
        },
        {
            name: 'novo-grupo',
            path: 'grupos/novo',
            component: () => import('@/pages/painel/grupos/novo/NovoGrupo.vue'),
        },
        {
            name: 'grupos-editar-permissoes',
            path: 'grupos/permissoes/:id',
            component: () => import('@/pages/painel/grupos/permissoes/EditarPermissoes.vue'),
            meta: {
                name: 'Editar Permissões',
            },
        },
    ]
}