export function validatePass(senha: string) {
    let caracteres = 0;
    let letraMaiscula = 0;
    let numero = 0;
    for (let i=0; i <= senha.length; i++) {
        caracteres++;

        const valorAscii = senha.charCodeAt(i);

        // de A até Z
        if (valorAscii >= 65 && valorAscii <= 90){
            letraMaiscula++;
        }

        // de 0 até 9
        if (valorAscii >= 48 && valorAscii <= 57){
            numero++;
        }
    }

    if((letraMaiscula >= 1) && (numero >= 1) && (caracteres > 6)){
        return true;
    } else {
        return `Sua senha precisa conter pelo menos 6 caracteres, números e letras maíusculas`;
    }
}
export function isFunction(treco: any) {
    return typeof treco === 'function';
}

export function isString(treco: any) {
    return typeof treco === 'string';
}

export function hasWhiteSpace(s: string) {
    return /\s/g.test(s);
}