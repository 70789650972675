import Swal from 'sweetalert2'

export async function SendAlert(title:string, background: string) {
    if (title && background){
        Swal.fire({
            title: title,
            toast: true,
            background:  background,
            color: '#fff',
            timer: 8000,
            showConfirmButton: false,
            timerProgressBar: true,
            showCloseButton: true,
            allowOutsideClick: true,
            allowEscapeKey: true,
            allowEnterKey: true,
            position: 'top-end',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        return true;
    }
    return false;
}