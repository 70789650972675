<template>
  <div class="app-layout__navbar">
    <va-navbar>
      <template v-slot:left>
        <div class="left">
          <va-icon-menu-collapsed 
            @click="$emit('changeSideBar')"
            :class="{ 'x-flip': isSidebarMinimized }"
            class="va-navbar__item"
            :color="colors.primary"
          />
          <router-link to="/">
            <img src="/img/logo.png" width="75">
          </router-link>          
        </div>
      </template>
      <template #center>
        <va-alert v-if="isAcessoOutro" color="warning" icon="info">
          <template #default>
            <div>
              Você está logado no perfil de:
              <strong>{{ $Auth.getProp('nome') }}</strong>&nbsp;
              <va-button icon="arrow_back" size="small" @click="voltarAoUserOriginal(this)">Voltar</va-button>
            </div>
          </template>
        </va-alert>
      </template>
      <template #right>
        <va-button-group>
          <va-button @click="$router.push({name:'perfil-trocar-senha', params: {id: $Auth.getProp('id')}})">Trocar Senha</va-button>
          <va-button color="warning" @click="$Auth.sair()" gradient space-between-items>Sair</va-button>
        </va-button-group>
      </template>
    </va-navbar>
  </div>
</template>

<script>
import { useColors } from 'vuestic-ui'
import { computed } from 'vue'
import VaIconMenuCollapsed from '@/components/icons/VaIconMenuCollapsed'
import {Auth} from "@/functions/Auth";

export default {
  components: { VaIconMenuCollapsed },
  props: {
    isSidebarMinimized: { type: Boolean, default: false },
  },
  setup() {
    const { getColors } = useColors()
    const colors = computed(() => getColors() )

    return {
      colors
    }
  },
  data() {
    return {
      $Auth: new Auth(),
      isAcessoOutro: window.localStorage.getItem('JWT_TOKEN_OLD') ?? false
    }
  },
  methods:{
    async voltarAoUserOriginal(vue){
      if( localStorage.getItem('JWT_TOKEN_OLD') ) {
        localStorage.setItem('JWT_TOKEN',localStorage.getItem('JWT_TOKEN_OLD'))
        localStorage.removeItem('JWT_TOKEN_OLD');
        vue.$router.push({path: '/'})
        setTimeout(() => location.reload(), 500)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

  .x-flip {
    transform: scaleX(-100%);
  }

  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
</style>