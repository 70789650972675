import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {Auth} from "@/functions/Auth";
import AuthRoutes from "@/router/AuthRoutes";
import PainelRoutes from "@/router/PainelRoutes";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/:catchAll(.*)",
        redirect: {name: 'painel-chamados-listagem'},
    },
    AuthRoutes,
    PainelRoutes
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
    routes
})

router.beforeEach(async (to, from, next) => {
    const $Auth = new Auth()
    if(!$Auth.logado){
        await $Auth.setLogin()
    }

    if ($Auth.logado && to.meta?.perm && !$Auth.checkPerm(to.meta.perm)) {
        $Auth.sair();
    }

    //@todo, precisamos desvincular o Paulo Cuja do AlperLab
    if ($Auth.logado && $Auth.getProp('nome') === 'Paulo') {
        $Auth.sair();
    }

    if (['login', 'signup'].includes(to.name?.toString() ?? '')) {
        if($Auth.logado) {next({path: '/'})}
    } else if (!$Auth.logado) {
        next({name: 'login'})
    }
    next()
})

export default router
