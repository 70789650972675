<template>
  <div class="row justify-center align-center va-spacing-y-10">
    <div class="py-5 flex xs12 sm12 md12 lg12 xl12">
      <router-link class="py-5 flex-center" to="/">
        <img src="/img/logo.png" width="200">
      </router-link>
    </div>
    <va-card class="flex xs12 sm12 md12 lg12 xl12 auth-layout__card">
      <va-card-content>
        <va-tabs v-model="tabIndex" center>
          <template #tabs>
            <va-tab name="login">Login</va-tab>
          </template>
        </va-tabs>
        <va-divider />
        <div>
          <router-view />
        </div>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>

export default {
  name: "AuthLayout",
  data() {
    return {
      selectedTabIndex: 0
    };
  },
  computed: {
    tabIndex: {
      set(tabName) {
        this.$router.push({ name: tabName });
      },
      get() {
        return this.$route.name;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-layout {
  min-height: 100vh;
  background-image: linear-gradient(to right, var(--va-background), var(--va-white));

  &__card {
    width: 100%;
    max-width: 600px;
  }
}
</style>
