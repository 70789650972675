<template>
  <div class="row justify-center align-center">
    <va-card :class="`flex xs12 sm12 md12 lg${Crud?.card?.lg ?? '10'} xl${Crud?.card?.xl ?? '10'}`">
      <va-card-content>
        <monta-listagem
          :table="Crud.listTable"
          @refresh="() => refresh(this)"
          @new="() => openModalNew(this)"
        >
          <template #cell(acoes_id)="{ source: acoes_id }">
            <va-button icon="visibility" class="" @click="modalActions = acoes_id"/>
          </template>
          <template v-for="(_, slot) in $slots" #[slot]="scope">
            <slot :name="slot" v-bind="scope || {}" />
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
    <va-modal v-model="modalActions">
      <div>
        <slot name="modalAditionalActions" :acoes_id="modalActions"/>
        <va-button v-if="Crud?.editForm" class="btn-dropdown" icon-right="edit" color="info" gradient @click="() => openModalEdit(this, modalActions)" size="medium">Editar</va-button><br>
        <va-button v-if="Crud?.remove?.endpoint" class="btn-dropdown" icon-right="delete" color="danger" gradient @click="modalRemove = modalLastId = modalActions;modalActions = false;" size="medium">Excluir</va-button>
      </div>
    </va-modal>
    <va-modal v-model="modalRemove" message="Deseja realmente excluir?" ok-text="Excluir" @ok="remove(modalLastId, this)" />
    <va-modal v-model="modalNew" hide-default-actions size="large">
      <monta-form :form="Crud.newForm" @close="modalNew = false" @complete="(data) => submitNewForm(this, data)"/>
    </va-modal>
    <va-modal v-if="Crud.editForm" v-model="modalEdit" hide-default-actions size="large">
      <monta-form :form="Crud.editForm" @close="modalEdit = false" @complete="(data) => submitEditForm(this, data)"/>
    </va-modal>
  </div>
</template>

<script>
import { refresh, remove, submitNewForm, submitEditForm, openModalEdit, openModalNew } from "./Functions"

export default {
  props: {
    Crud: {
      type: Object
    }
  },
  mounted(){
    if (this?.Crud?.newForm?.data?.autoOpen){
      openModalNew(this)
    }
  },
  data() {
    return {
      modalNew: false,
      modalRemove: false,
      modalEdit: false,
      modalActions: false,
      modalLastId: false,
    }
  },
  methods:{
    refresh,
    remove,
    submitNewForm,
    openModalEdit,
    submitEditForm,
    openModalNew
  },
}
</script>

<style scoped>
.btn-dropdown{
  min-width: 165px;
  margin-bottom: 3px;
}

</style>