let countPromisses = 0

export function LoaderInit(){
    countPromisses++;
    // @ts-ignore
    document.getElementById('loader').style.display = 'block';

}
export function LoaderStop(){
    countPromisses--;
    if (countPromisses === 0){
        // @ts-ignore
        document.getElementById('loader').style.display = 'none';
    }
}