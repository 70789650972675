<template>
  <div class="row">
    <monta-input
      v-for="item in row"
      :key="item.name"
      :item="item"
      :validate-func="item.validate"
      :all="all"
      @update-input="updateInput"
      ref="inputRef"
    />
  </div>
</template>

<script>
import MontaInput from "@/components/monta-form/MontaInput";
export default {
  name: "MontaRow",
  components:{MontaInput},
  props:[
      'row',
      'all'
  ],
  methods: {
    updateAllInputs(){
      Object.entries(this.$refs.inputRef).forEach(([key]) => {
        this.$refs.inputRef[key].validate()
        this.$refs.inputRef[key].updateInput()
      });
    },
    updateInput(input){
      if(input.name !== undefined && input.val !== undefined){
        this.$emit("updateInput",input);
      }
    }
  }
}
</script>

<style scoped>

</style>
