export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'em-breve',
      displayName: 'Programação',
      meta: {
        icon: 'code',
      },
      children: [
        {
          name: 'painel-chamados-listagem',
          displayName: 'Listagem de Chamados',
        },
        {
          name: 'painel-chamados-listagem-pontuacao',
          displayName: 'Relatório de Pontos',
        },
        {
          name: 'graficos',
          displayName: 'Graficos',
        },
      ],
    },
    {
      name: 'clientes',
      displayName: 'Clientes',
      meta: {
        icon: 'groups',
      }
    },
    {
      name: 'em-breve',
      displayName: 'Gestão',
      meta: {
        icon: 'settings',
      },
      children: [
        {
          name: 'usuarios',
          displayName: 'Usuários',
        },
        {
          name: 'grupos',
          displayName: 'Grupos',
        },
      ],
    },
  ],
}
