import {sleep} from "@/functions/Sleep";

export function endRefresh(vue: any) {
    vue.autoRefresh.percent = 0;
    vue.autoRefresh.loading = false;
    autoRefeshControl(vue);
}

export function refresh(vue: any) {
    if(vue.loaded && (!vue.autoRefresh.loading || vue.realyTable.refresh === true)) {
        vue.autoRefresh.loading = true;
        vue.autoRefresh.percent = 0;
        vue.$emit('refresh', false)
    }
    vue.realyTable.refresh = false;
}

export async function autoRefeshControl(vue: any) {
    if(vue.loaded && vue.table.enableAutoRefresh && !vue.autoRefresh.loading){
        let sleepTime = vue.autoRefresh.sleepSeconds * 10;
        if (sleepTime < 300) {
            sleepTime = 300;
        }
        await sleep(sleepTime);
        vue.autoRefresh.percent = vue.autoRefresh.percent + 1;
        if(vue.autoRefresh.percent > 100){
            vue.autoRefresh.percent = 1;
            refresh(vue);
        } else {
            autoRefeshControl(vue);
        }
    }
}

function getLsKey(vue: any){
    return vue.realyTable.name + "-columnsSelectedsKeys";
}

export function reloadColumns(vue: any){
    vue.columnsSelecteds = vue.realyTable.columns.filter((col:any)=>{
        return Object.keys(vue.realyTable.columnsSelectedsKeys).includes(col.key) && vue.realyTable.columnsSelectedsKeys[col.key]
    })
    vue.showModalPersonalizar = false;
}

export function loadDataFromStorage(vue: any){
    salvaEstruturaOriginal(vue);
    const lsKey = getLsKey(vue)
    if (window.localStorage && localStorage.getItem(lsKey)) {
        vue.realyTable.columnsSelectedsKeys = JSON.parse(localStorage.getItem(lsKey) ?? '')
    }
    reloadColumns(vue);
}

export function salvaEstruturaOriginal(vue: any){
    const lsKey = getLsKey(vue) + "-original"
    if (window.localStorage) {
        localStorage.setItem(lsKey, JSON.stringify({...vue.table.columnsSelectedsKeys}))
    }
}

export function salvaEstrutura(vue: any){
    const lsKey = getLsKey(vue)
    if (window.localStorage) {
        localStorage.setItem(lsKey, JSON.stringify({...vue.realyTable.columnsSelectedsKeys}))
    }
    reloadColumns(vue)
}

export function resetarEstrutura(vue: any){
    const lsKey = getLsKey(vue)
    if (window.localStorage) {
        vue.realyTable.columnsSelectedsKeys = vue.table.columnsSelectedsKeys
        localStorage.removeItem(lsKey)
        if (localStorage.getItem(lsKey + "-original")) {
            vue.realyTable.columnsSelectedsKeys = JSON.parse(localStorage.getItem(lsKey + "-original") ?? '')
        }
    }
    reloadColumns(vue)
}
